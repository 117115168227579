import React, { useContext, useEffect } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { withPreview } from "gatsby-source-prismic";
import { createBreakpoint } from "react-use";

// Context
import { PageTypeContext } from "../components/context/page-type";
import { ActiveListingCity } from "../components/context/active-city";
import { ActiveListingCountry } from "../components/context/active-country";

// Components
import { Listing, H1Heading } from "../components/listing/listing-components";
import { AToZ } from "../components/homepage/a-to-z";
import { AspectRatioImageContainer } from "../components/containers/aspect-ratio-image-container";

// Containers
import { MasonryContainer } from "../components/containers/masonry-container";
import { AllListings } from "../components/maps/all-listings-map";

const Page = styled.div`
  padding: 0 90px;

  @media (max-width: 1200px) {
    padding: 0 60px;
  }

  @media (max-width: 1000px) {
    padding: 0 40px;
  }

  @media (max-width: 768px) {
    padding: 0 20px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 60px;
  grid-row-gap: 80px;
`;

const useBreakpoint = createBreakpoint({ M: 601, S: 10 });

const Index = ({ data }) => {
  const [pageTypeContext, setPageTypeContext] = useContext(PageTypeContext);
  const [activeCity, setActiveCity] = useContext(ActiveListingCity);
  const [activeCountry, setActiveCountry] = useContext(ActiveListingCountry);
  const breakpoint = useBreakpoint();

  useEffect(() => {
    setPageTypeContext(`home`);
    setActiveCountry(null);
    setActiveCity(null);
  }, []);

  const content = data.allPrismicListing.edges.map((content, index) => (
    <Listing key={`homepage_listing_${content.node.id}`}>
      <Link to={content.node.url}>
        {content.node.data.thumbnail.fluid !== null && (
          <AspectRatioImageContainer image={content.node.data.thumbnail}>
            <img
              src={content.node.data.thumbnail.fluid.srcWebp}
              srcSet={content.node.data.thumbnail.fluid.srcSetWebp}
              alt={content.node.data.thumbnail.alt}
              loading="lazy"
            />
          </AspectRatioImageContainer>
        )}
      </Link>
      <div className="listing-title">
        <p className="transcript">
          <Link to={content.node.url}>{content.node.data.title.text}</Link>
        </p>
        <p className="transcript">
          {content.node.data.city.document !== null ? (
            <Link
              to={content.node.data.city.document.url}
              className="city-link"
            >
              near {content.node.data.city.document.data.name.text}
            </Link>
          ) : (
            <Link
              to={content.node.data.country.document.url}
              className="city-link"
            >
              {content.node.data.country.document.data.name.text}
            </Link>
          )}
        </p>
      </div>
    </Listing>
  ));

  return (
    <Page>
      <Grid>
        <H1Heading className="transcript">
          Recently added to Hour directory
        </H1Heading>
      </Grid>

      <MasonryContainer>{content}</MasonryContainer>

      <AllListings
        locations={data.allPrismicListing.edges}
        breakpoint={breakpoint}
      />

      <AToZ />
    </Page>
  );
};

export default withPreview(Index);

export const query = graphql`
  {
    allPrismicListing(sort: { fields: first_publication_date, order: DESC }) {
      edges {
        node {
          id
          url
          data {
            title {
              text
            }
            thumbnail {
              alt
              dimensions {
                height
                width
              }
              fluid {
                srcWebp
                srcSetWebp
                aspectRatio
              }
            }
            city {
              document {
                ... on PrismicCity {
                  id
                  url
                  data {
                    name {
                      text
                    }
                  }
                }
              }
            }
            country {
              document {
                ... on PrismicCountry {
                  id
                  url
                  data {
                    name {
                      text
                    }
                  }
                }
              }
            }
            location {
              latitude
              longitude
            }
          }
        }
      }
    }
  }
`;
