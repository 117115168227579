import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import styled from "styled-components";

const AToZContainer = styled.div`
  padding: 40px;
  margin: 0 0 100px 0;

  background-color: rgb(208 209 204);

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 60px;
  grid-row-gap: 80px;

  @media (max-width: 768px) {
    display: block;
  }
`;

const H2Heading = styled.h2`
  font-size: 20px;
  line-height: 22px;
  letter-spacing: 0.05em;

  grid-column: span 2;

  @media (max-width: 768px) {
    margin: 0 0 30px 0;
  }
`;

const AllListings = styled.ol`
  grid-column: span 4;
`;

const SingleLetterListing = styled.li`
  margin: 0 0 30px 0;

  & .letter,
  & .spacer {
    width: 32px;
    margin: 0 60px 0 0;
    display: inline-block;

    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.05em;

    text-align: center;

    @media (max-width: 768px) {
      font-size: 20px;
      line-height: 1.5;

      margin: 0;

      text-align: left;
    }
  }

  & .location {
    font-size: 32px;
    line-height: 1.5;
    letter-spacing: 0.05em;

    text-decoration: none;
    border-bottom: 2px solid rgb(14 53 99);
    transition: 250ms border-bottom ease;

    @media (max-width: 768px) {
      font-size: 20px;
      line-height: 1.5;
      letter-spacing: 0.05em;
    }
  }

  @media (max-width: 768px) {
    margin: 0 0 20px 0;
  }
`;

export const AToZ = () => {
  const data = useStaticQuery(graphql`
    {
      allPrismicCountry {
        edges {
          node {
            url
            type
            data {
              name {
                text
              }
              cities {
                city {
                  document {
                    ... on PrismicCity {
                      id
                      uid
                      data {
                        name {
                          text
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const allCountries = data.allPrismicCountry.edges.map(
    country => country.node
  );
  const allCities = data.allPrismicCountry.edges
    .map(country =>
      country.node.data.cities
        .filter(city => city.city.document !== null)
        .map(city => {
          return {
            data: city.city.document.data,
            type: "city",
            url: `${country.node.url}/${city.city.document.uid}`,
          };
        })
    )
    .flat();

  const allLocations = [...allCountries, ...allCities].flat();
  const sortedLocations = allLocations.sort((a, b) =>
    a.data.name.text.localeCompare(b.data.name.text)
  );

  //https://stackoverflow.com/questions/51009090/sort-and-group-objects-alphabetically-by-first-letter-javascript
  const groupedData = sortedLocations.reduce((r, e) => {
    // get first letter of name of current element
    let group = e.data.name.text[0];
    // if there is no property in accumulator with this letter create it
    if (!r[group]) r[group] = { group, children: [e] };
    // if there is push current element to children array for that letter
    else r[group].children.push(e);
    // return accumulator
    return r;
  }, {});

  // since data at this point is an object, to get array of values
  // we use Object.values method
  const formattedData = Object.values(groupedData);

  const list = formattedData.map((location, outerIndex) => {
    let locationListings = location.children.map((child, index) => (
      <li key={`location_${outerIndex}_listing_${index}`}>
        {index === 0 && <span className="letter">{location.group}</span>}
        {index !== 0 && <span className="spacer" />}
        <Link to={child.url} className="location">
          {child.type === "city" && `near `}
          {child.data.name.text}
        </Link>
      </li>
    ));

    return (
      <SingleLetterListing key={`all_locations_${outerIndex}`}>
        <ol className="transcript">{locationListings}</ol>
      </SingleLetterListing>
    );
  });

  return (
    <AToZContainer>
      <Grid>
        <H2Heading className="transcript">A-Z of countries and areas</H2Heading>
        <AllListings>{list}</AllListings>
      </Grid>
    </AToZContainer>
  );
};
