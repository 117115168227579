import React from "react";
import styled from "styled-components";

export const AvailableSoon = styled.div`
  padding: 30px;
  margin: 0 0 37px 0;

  background: rgba(33, 96, 25, 0.1);

  & .heading {
    padding: 0 60px;

    font-size: 20px;
    line-height: 22px;
    letter-spacing: 0.05em;

    color: #225f18;

    @media (max-width: 1000px) {
      padding: 0 20px;
    }

    @media (max-width: 768px) {
      font-size: 17px;
      line-height: 21px;
      letter-spacing: 0.05em;
    }

    @media (max-width: 699px) {
      padding: 0;
    }
  }

  @media (max-width: 1000px) {
    padding: 30px 20px;
  }

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

export const H1Heading = styled.h1`
  font-size: 20px;
  line-height: 22px;
  letter-spacing: 0.05em;

  margin: 30px 0 116px 0;

  grid-column: span 3;

  @media (max-width: 1000px) {
    font-size: 17px;
    line-height: 22px;
    letter-spacing: 0.05em;
  }

  @media (max-width: 768px) {
    margin: 20px 0 75px 0;
  }
`;

export const Listing = styled.div`
  transition: 250ms all ease;

  & .listing-title {
    margin: 11px 0 0 0;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: baseline;

    & p {
      margin: 0;
    }

    & .crimson {
      font-size: 17px;
      line-height: 23px;
      letter-spacing: 0.03em;
    }

    & .transcript {
      font-size: 16px;
      line-height: 23px;
      letter-spacing: 0.05em;
    }

    & a.city-link {
      text-decoration: none;
      border-bottom: 2px solid rgb(14 53 99);

      display: inline-block;
      line-height: 22px;

      @media (max-width: 768px) {
        line-height: 16px;
      }
    }

    @media (max-width: 1000px) {
      & .crimson,
      & .transcript {
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.05em;
      }
    }

    @media (max-width: 768px) {
      display: block;
    }
  }
`;

export const AvailableSoonListing = styled(Listing)`
  padding: 0 30px;

  @media (max-width: 1200px) {
    padding: 0 20px;
  }

  @media (max-width: 699px) {
    padding: 0 20px;
  }

  & a {
    display: block;
  }

  & img {
    display: block;
    object-fit: cover;
  }

  & .listing-container {
    background-color: #fff;
    padding: 23px;

    @media (max-width: 899px) {
      padding: 15px;
    }

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: baseline;

    color: rgba(34, 95, 24, 1);

    & p {
      margin: 0;
    }

    & .crimson {
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.03em;

      @media (max-width: 768px) {
        font-size: 18px;
        line-height: 21px;
      }
    }

    & .transcript {
      font-size: 19px;
      line-height: 20px;
      letter-spacing: 0.04em;

      @media (max-width: 768px) {
        font-size: 17px;
        letter-spacing: 0.05em;
      }
    }

    & .travel-time {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      & em {
        align-self: baseline;
      }
      & .dots {
        line-height: 2px;
        height: 2px;

        font-weight: 100;

        padding: 0 2px;
        margin: 7px 0 0 0;
      }
    }

    @media (max-width: 768px) {
      & .details {
        width: 100%;
      }
    }
  }

  & .mobile-title {
    display: none;

    @media (max-width: 1130px) {
      display: block;
    }
  }
  .desktop-title {
    @media (max-width: 1130px) {
      display: none;
    }
  }
`;

export const SortContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  letter-spacing: 0.07em;

  & p {
    margin: 0 15px 0 0;
  }

  & button {
    border: 0;
    background-color: transparent;
    padding: 0;
    margin: 0 15px;
    border-bottom: 2px solid #fff;
    letter-spacing: 0.05em;

    display: inline-block;
    position: relative;
    border-bottom: 2px solid transparent;

    &.active-sort {
      border-bottom: 2px solid rgb(14 53 99);
    }

    &:last-of-type {
      margin: 0 0 0 15px;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    margin: 70px 0 0 0;

    justify-content: flex-start;

    & p,
    & button {
      font-size: 17px;
      line-height: 21px;
      letter-spacing: 0.05em;
    }
  }

  @media (max-width: 500px) {
    & .sort-buttons {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      width: calc(100% - 55px);

      & button {
        margin: 0;
      }
    }
  }
`;
