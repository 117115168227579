import React from "react";
import styled from "styled-components";
import Masonry from "react-masonry-css";

const Container = styled.div`
  .my-masonry-grid {
    display: flex;
    margin-left: -60px;
    width: auto;

    @media (max-width: 1000px) {
      margin-left: -40px;
    }

    @media (max-width: 768px) {
      margin-left: -20px;
    }
  }
  .my-masonry-grid_column {
    padding-left: 60px;
    background-clip: padding-box;

    @media (max-width: 1000px) {
      padding-left: 40px;
    }

    @media (max-width: 768px) {
      padding-left: 20px;
    }
  }

  .my-masonry-grid_column > div {
    margin-bottom: 80px;

    @media (max-width: 768px) {
      margin-bottom: 30px;
    }
  }
`;

// Masonry
const breakpointColumnsObj = {
  default: 3,
  767: 2,
};

export const MasonryContainer = ({ children, image, padding }) => (
  <Container padding={padding}>
    <Masonry
      breakpointCols={breakpointColumnsObj}
      className="my-masonry-grid"
      columnClassName="my-masonry-grid_column"
    >
      {children}
    </Masonry>
  </Container>
);
